import React from "react";
import styled from "styled-components";
import { Layout, Hero, About, Jobs, Featured, Contact } from "../components";


const StyledMainContainer = styled.main`
  counter-reset: section;
`;

type Props = {
  location: any;
};


const IndexPage = ({ location }: Props): JSX.Element => {
  return (
    <div>
        <Layout location={location}>
          <StyledMainContainer className="fillHeight">
            <Hero />
            <About />
            <Jobs />
            <Featured />
            {/*    <Projects /> */}
            <Contact />
          </StyledMainContainer>
        </Layout>
    </div>
  );
};

export default IndexPage;
